import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation('common');
  return (
    <footer><p>© 2021 - {t('general.by')} Marine Hansart</p></footer>
  );
}

export default Footer;
