import { useTranslation } from 'react-i18next';
import ContactForm from '../contact_form/ContactForm';
import Section from '../section/Section';
import Title from '../title/Title';

function SectionContact() {
  const { t } = useTranslation('common');
  return (
    <Section section="contact" extremite first>
      <div className="content-container">

        <Title titleBalise="contact" title="Contact" first={false} />
        <div className="text-contact" data-aos="fade-up" dangerouslySetInnerHTML={{ __html: t('contact.description') }} />
        <ContactForm />
        <div className="balise balise-closing" data-aos="fade-up">
          &lt;<span className="balise-name">/div</span>&gt;
        </div>
        <div className="balise body" data-aos="fade-up">&lt;/body&gt;</div>
      </div>

    </Section>
  );
}

export default SectionContact;
