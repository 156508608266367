import { useTranslation } from 'react-i18next';
import Section from '../section/Section';
import Title from '../title/Title';
import Bouton from '../bouton/Bouton';

function SectionAbout() {
  const { t } = useTranslation('common');

  return (
    <Section section="about" extremite={false} first={false}>
      <div className="picture d-flex" data-aos="fade-up">
        <img src="marinehansart.jpg" alt="Marine Hansart" />
      </div>
      <div className="content-container">
        <Title titleBalise={t('about.balise')} title={t('about.title')} first={false} />
        <div className="text-content" data-aos="fade-up">
          <p dangerouslySetInnerHTML={{ __html: t('about.description1') }} />
          <p dangerouslySetInnerHTML={{ __html: t('about.description2') }} />
        </div>
        <div className="balise script" data-aos="fade-up">&lt;script&gt;</div>
        <div className="array balise" data-aos="fade-up">
          <div><span className="const">const</span> {t('about.who-m-i')} = &#123;</div>
          <div className="characteristics"><span className="category">{t('general.name').toLowerCase()}</span> : &quot;<span className="balise-class-name">Marine</span>&quot;,</div>
          <div className="characteristics"><span className="category">age</span> : &quot;<span className="balise-class-name">{t('about.age')}</span>&quot;,</div>
          <div className="characteristics"><span className="category">{t('about.location')}</span> : <span className="balise-number">1420</span>,</div>
          <div className="characteristics"><span className="category">job</span> : &quot;<span className="balise-class-name">Front-End/Fullstack Developer</span>&quot;,</div>
          <div className="array-closing">&#125;;</div>
        </div>
        <div data-aos="fade-up" className="balise script closing">&lt;/script&gt;</div>
        <div data-aos="fade-up" className="div-btn"><Bouton link="#contact" target={false} content={t('general.contact-me')} /></div>
        <div data-aos="fade-up" className="balise section-name">
          &lt;<span className="balise-name">/div</span>&gt;
        </div>
      </div>

    </Section>
  );
}

export default SectionAbout;
