import React, { useState, useEffect } from 'react';

import ReactDOM from 'react-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18next from 'i18next';

import App from './App';

import commonFr from './translations/fr/common.json';
import commonEn from './translations/en/common.json';

import './styles/style.scss';
import reportWebVitals from './reportWebVitals';
import Loader from './loader/Loader';

const Portfolio = () => {
  const [t, i18n] = useTranslation('common');
  const [isLoading, setIsLoading] = useState(true);
  const [scroll, setScroll] = useState('hidden');
  document.body.style.overflowY = scroll;
  if (window.location.pathname === '/') {
    window.location.pathname = '/fr';
  }
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setScroll('scroll');
    }, 1800);
  }, []); // here

  return (
    <div>
      {isLoading && <Loader />}
      <App />
    </div>
  );
};
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'fr', // language to use
  resources: {
    en: {
      common: commonEn, // 'common' is our custom namespace
    },
    fr: {
      common: commonFr,
    },
  },
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Portfolio />
  </I18nextProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
