import { useTranslation } from 'react-i18next';
import Section from '../section/Section';
import Title from '../title/Title';

const { default: Bouton } = require('../bouton/Bouton');

function SectionHome() {
  const { t } = useTranslation('common');
  return (
    <Section section="home" extremite first>
      <div className="content-container">
        <div className="balise body">&lt;body&gt;</div>
        <Title titleBalise={t('home.balise')} title={t('home.title')} first />
        <div data-aos="fade-up" data-aos-delay="2400" className="presentation">
          <p>
            {t('home.i-am')} <span className="m">M</span><span className="my-name">arine,</span>
          </p>
          <p>{t('home.function')}.</p>
        </div>
        <div data-aos="fade-up" data-aos-delay="2400"><Bouton link="#contact" content={t('general.contact-me')} target={false} /></div>
        <div className="balise" data-aos="fade-right" data-aos-delay="2200">
          &lt;<span className="balise-name">/div</span>&gt;
        </div>
      </div>
      <div />

    </Section>
  );
}

export default SectionHome;
