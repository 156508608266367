import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function SwitchLang() {
  const [t, i18n] = useTranslation('common');
  const { pathname } = window.location;
  const [value, setValue] = useState(['/fr', '/en'].includes(pathname) ? pathname !== '/fr' : false);
  function handleChangeLang() {
    setValue(!value);
    const lang = value ? 'fr' : 'en';
    i18n.changeLanguage(lang);
    window.history.pushState({}, null, `/${lang}`);
  }
  useEffect(() => {
    i18n.changeLanguage(!value ? 'fr' : 'en');
  }, []);

  return (
    <div className="switch">
      <input id="language-toggle" className="check-toggle check-toggle-round-flat" type="checkbox" checked={value} onChange={handleChangeLang} />
      <label htmlFor="language-toggle" />
      <span className="on">FR</span>
      <span className="off">EN</span>
    </div>
  );
}

export default SwitchLang;
