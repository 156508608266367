import { useTranslation } from 'react-i18next';
import Title from '../title/Title';
import Section from '../section/Section';

const { default: Bouton } = require('../bouton/Bouton');

function SectionWork() {
  const { t } = useTranslation('common');
  return (
    <Section section="work" extremite={false} first={false}>
      <div className="content-container">
        <Title titleBalise={t('work.balise')} title={t('work.title')} first={false} />
        <div className="cube-container d-flex">
          <div className="d-flex w-100" data-aos="fade-up">
            <div className="one-work">
              <img
                src="jollyseed.gif"
                alt="jolly-seed site"

              />
            </div>
            <div className="text-work">
              <div className="work-title">Jolly Seed</div>
              <p dangerouslySetInnerHTML={{ __html: t('work.jolly-seed') }} />
              <div className="balise">
                <span className="const">const</span> login =  &#123; <p className="characteristic"><span className="category">{t('general.psw')}</span> : &quot;<span className="balise-class-name">admin</span>&quot;, <span className="category">email</span> : &quot;<span className="balise-class-name">admin@gmail.com</span>&quot;</p><span className="balise-closing"> &#125;;</span>
              </div>
              <p className="bold">Html, Scss, Php, Javascript, Ajax, MySql</p>
              <div className="d-flex button-class">
                <Bouton link="https://jolly-seed.herokuapp.com/" content={t('work.site')} target />
                <Bouton link="https://github.com/mhansart/jolly_seed" content={t('work.code')} target />
              </div>
            </div>
          </div>
          <div className="d-flex w-100" data-aos="fade-up">
            <div className="text-work">
              <div className="work-title">Skywalkers - Ultimate mixed team</div>
              <p dangerouslySetInnerHTML={{ __html: t('work.ultimate') }} />
              <p className="bold">Html, Scss, React, Javascript, React-router</p>
              <div className="d-flex button-class">
                <Bouton link="http://ultimate-skywalkers.be/#/" content={t('work.site')} target />
                <Bouton link="https://github.com/mhansart/skywalkers-react-router-nodemailer" content={t('work.code')} target />
              </div>
            </div>
            <div className="one-work">
              <img
                src="skywalkers.png"
                alt="skywalkers site"

              />
            </div>
          </div>
          <div className="d-flex w-100" data-aos="fade-up">
            <div className="one-work">
              <img
                src="hackerflix.gif"
                alt="exercice js"

              />
            </div>
            <div className="text-work">
              <div className="work-title">Hackerflix</div>
              <p dangerouslySetInnerHTML={{ __html: t('work.hackerflix') }} />
              <p className="bold">Html, Scss, Javascript</p>
              <div className="d-flex button-class">
                <Bouton link="https://mhansart.github.io/epreuve-blanche-js-if3/" content={t('work.site')} target />
                <Bouton link="https://github.com/mhansart/epreuve-blanche-js-if3" content={t('work.code')} target />
              </div>
            </div>
          </div>
          <div className="d-flex w-100" data-aos="fade-up">
            <div className="text-work">
              <div className="work-title">Balise-Man</div>
              <p dangerouslySetInnerHTML={{ __html: t('work.balise-man') }} />
              <p className="bold">Html, Scss, Javascript, Jquery</p>
              <div className="d-flex button-class">
                <Bouton link="https://emontaigne.github.io/hackathon_if3/" content={t('work.site')} target />
                <Bouton link="https://github.com/emontaigne/hackathon_if3/tree/master" content={t('work.code')} target />
              </div>
            </div>
            <div className="one-work">
              <img
                src="hackathon.png"
                alt="hackathon site"

              />
            </div>
          </div>
          <div className="d-flex w-100" data-aos="fade-up">
            <div className="one-work">
              <img
                src="smoothbook.png"
                alt="smoothbook site"
              />
            </div>
            <div className="text-work">
              <div className="work-title">SmoothBook</div>
              <p dangerouslySetInnerHTML={{ __html: t('work.smoothbook') }} />
              <div className="balise">
                <span className="const">const</span> login =  &#123; <p className="characteristic"><span className="category">{t('general.psw')}</span> : &quot;<span className="balise-class-name">admin</span>&quot;, <span className="category">email</span> : &quot;<span className="balise-class-name">admin@gmail.com</span>&quot;</p><span className="balise-closing"> &#125;;</span>
              </div>
              <p className="bold">Html, Scss, Javascript, Angular, NodeJs</p>
              <div className="d-flex button-class">
                <Bouton link="https://smoothbook-demo.netlify.app/connexion" content={t('work.site')} target />
                <Bouton link="https://github.com/mhansart/if3-smoothbook" content={t('work.code')} target />
              </div>
            </div>
          </div>
        </div>
        <div
          className="balise balise-closing"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          &lt;<span className="balise-name">/div</span>&gt;
        </div>
      </div>
      <div />
    </Section>
  );
}

export default SectionWork;
